import moment from "moment";
import React from "react";

import { useRefreshComponent } from "../../util/useRefreshComponent";

export const DAYS_OF_WEEK: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const DAYS_OF_MONTH: string[] = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
];

export interface HumanDateProps {
  date: Date | string;
  toDate?: Date | string;
  className?: string;
  withoutSuffix?: boolean;
  title?: boolean;
  updateEverySecond?: boolean;
  abbreviateUnits?: boolean;

  // show results less than a minute ago as 'Just now'
  smoothFirstMinute?: boolean;
}

const MINUTE_IN_MILLISECONDS = 60 * 1000;
const WEEK_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60 * 24 * 7;

export const HumanDate: React.FunctionComponent<HumanDateProps> = ({
  abbreviateUnits = false,
  className,
  date,
  smoothFirstMinute = false,
  title = true,
  toDate = undefined,
  updateEverySecond = false,
  withoutSuffix = false,
}) => {
  if (updateEverySecond || abbreviateUnits) {
    moment.relativeTimeThreshold("ss", 4);
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "1s",
        ss: "%ds",
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        w: "1w",
        ww: "%dw",
        M: "1m",
        MM: "%dm",
        y: "1y",
        yy: "%dy",
      },
    });
  } else {
    moment.relativeTimeThreshold("ss", 1);
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "1 sec",
        ss: "%d secs",
        m: "1 min",
        mm: "%d min",
        h: "1 hr",
        hh: "%d hrs",
        d: "1 day",
        dd: "%d days",
        w: "1 week",
        ww: "%d weeks",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years",
      },
    });
  }

  const endMoment = toDate ? moment(toDate) : moment();
  const momentDate = moment(date);

  const rawMillisecondsDiff = endMoment.valueOf() - momentDate.valueOf();
  const isPast = rawMillisecondsDiff > 0;
  const millisecondsDiff = Math.abs(rawMillisecondsDiff);

  // Intelligently choose update interval
  let updateInterval: number;
  if (toDate) {
    updateInterval = -1;
  } else if (updateEverySecond && millisecondsDiff < 45 * 1000) {
    updateInterval = 1000;
  } else if (millisecondsDiff < 90 * 1000) {
    // If near a minute, update every 15 seconds
    updateInterval = 1000 * 15;
  } else if (millisecondsDiff < 1.5 * 60 * 60 * 1000) {
    // If under 1.5 hours update every minute
    updateInterval = 1000 * 60;
  } else {
    // Otherwise update once an hour
    updateInterval = 1000 * 60 * 60;
  }

  useRefreshComponent(updateInterval);

  let humanString: string;
  if (
    smoothFirstMinute &&
    isPast &&
    millisecondsDiff < MINUTE_IN_MILLISECONDS
  ) {
    humanString = "Just now";
  } else if (millisecondsDiff < WEEK_IN_MILLISECONDS) {
    humanString = momentDate.from(endMoment, withoutSuffix);
  } else {
    humanString = momentDate.format("ll");
  }

  return (
    <span
      className={className}
      title={title ? moment(date).toLocaleString() : undefined}
    >
      {humanString}
    </span>
  );
};
