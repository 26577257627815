import { OrgRole } from "@hex/common";
import React, { useMemo } from "react";

import { useCurrentUser } from "../../hooks/me/useCurrentUser";

export interface MyOrgRoleDependentRendererProps {
  allowedOrgRoles: readonly OrgRole[];
  children: (allowed: boolean) => React.ReactElement;
}

export function MyOrgRoleDependentRenderer({
  allowedOrgRoles,
  children,
}: MyOrgRoleDependentRendererProps): React.ReactElement {
  const currentUser = useCurrentUser();
  const allowedSet = useMemo(() => new Set(allowedOrgRoles), [allowedOrgRoles]);

  const orgRole = currentUser?.orgRole;
  return children(orgRole != null && allowedSet.has(orgRole));
}
