import { Classes } from "@blueprintjs/core";
import { OrgId, OrgRole, humanReadableOrgRole } from "@hex/common";
import React, { useMemo } from "react";
import styled, { css, useTheme } from "styled-components";

import { HexMenuItem, HexTooltip } from "../../hex-components";
import { ORG_ID } from "../../orgs";
import {
  BasicOrgFragmentForPickerFragment,
  WorkspacePickerFragment,
} from "../../orgs.generated";
import { Routes } from "../../route/routes";
import { LogInIcon, TickIcon } from "../icons/CustomIcons";

const ScrollableWorkspaceWrapper = styled.div<{ $isInTopNav: boolean }>`
  max-height: 50vh;
  margin: 0 -5px 5px;
  padding: 0 5px 5px;
  overflow-y: auto;
  max-width: 260px;

  border-bottom: 1px solid ${({ theme }) => theme.borderColor.MUTED};

  ${({ $isInTopNav, theme }) =>
    $isInTopNav &&
    css`
      border-top: 1px solid ${theme.borderColor.MUTED};
      margin-top: 5px;
    `}
`;

const RowItem = styled(HexMenuItem)`
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;

  > div {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  min-width: 0;

  padding: 6px 8px;
`;

const RowTitle = styled.div`
  display: block;
  gap: 5px;
  min-width: 0;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
`;

const RowBody = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
`;

const RowBodySubtitle = styled.div`
  display: flex;
  gap: 3px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const HoverLoginIcon = styled(LogInIcon)`
  opacity: 0;

  transition: opacity ${({ theme }) => theme.animation.durationMedium}
    ${({ theme }) => theme.animation.easing};
`;

const LoginTooltip = styled(HexTooltip)`
  && {
    display: flex;
    width: 100%;

    &:hover {
      ${HoverLoginIcon} {
        opacity: 1;
      }
    }
  }
`;

const NavPickerDivider = styled.div`
  width: 1px;
  margin: 5px 3px;

  background-color: ${({ theme }) => theme.borderColor.DEFAULT};
`;

const EmailMenuItemHeader = styled.div`
  padding: 12px 8px 6px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

export const OrgSubtitleDetails = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

interface NavOrgRowProps {
  id: OrgId;
  displayName: string;
  orgRole?: OrgRole;
  needsLogin?: boolean;
  userCount?: number;
}

export const NavOrgRow: React.ComponentType<NavOrgRowProps> = React.memo(
  function OrgRow({ displayName, id, needsLogin, orgRole, userCount }) {
    const maybePlural = userCount !== 1 ? "s" : "";
    const active = id === ORG_ID;
    const theme = useTheme();

    return (
      <RowItem
        active={active}
        href={Routes.ORG.getUrl({ orgId: id })}
        labelElement={
          active || needsLogin ? (
            <>
              {active && <TickIcon color={theme.menuItem.activeText} />}
              {needsLogin && <HoverLoginIcon />}
            </>
          ) : null
        }
        text={
          <>
            <LoginTooltip
              content={<>Log into this workspace</>}
              disabled={!needsLogin}
              placement="bottom-end"
            >
              <Row>
                <RowBody>
                  <RowTitle
                    className={Classes.TEXT_OVERFLOW_ELLIPSIS}
                    title={displayName}
                  >
                    {displayName}
                  </RowTitle>
                  <RowBodySubtitle>
                    {userCount && (
                      <OrgSubtitleDetails>
                        {userCount} member{maybePlural}
                      </OrgSubtitleDetails>
                    )}
                    {orgRole && userCount && <NavPickerDivider />}
                    {orgRole && (
                      <OrgSubtitleDetails>
                        {humanReadableOrgRole(orgRole)}
                      </OrgSubtitleDetails>
                    )}
                  </RowBodySubtitle>
                </RowBody>
              </Row>
            </LoginTooltip>
          </>
        }
      />
    );
  },
);

interface NavWorkspacePickerProps {
  authedUsers?: readonly WorkspacePickerFragment[];
  unauthUsers?: readonly BasicOrgFragmentForPickerFragment[];
  isInTopNav?: boolean;
}

type UserSection = {
  authed: WorkspacePickerFragment[];
  unauthed: BasicOrgFragmentForPickerFragment[];
};

export const NavWorkspacePicker: React.FunctionComponent<
  NavWorkspacePickerProps
> = ({ authedUsers, isInTopNav = false, unauthUsers }) => {
  // iterate over auth and unauthed users to map them to the email so in
  // UI we can show them grouped by email
  // We use a map of email to tuple to easily retain types
  const emailToUsersMap = useMemo(() => {
    const emailToUserMapInner: Map<string, UserSection> = new Map();

    // set up mappin of email to authed users as tuples first index
    authedUsers?.forEach((user: WorkspacePickerFragment) => {
      const users: UserSection = emailToUserMapInner.get(user.email) ?? {
        authed: [],
        unauthed: [],
      };
      users.authed.push(user);
      emailToUserMapInner.set(user.email, users);
    });

    // set up mappin of email to unauthed users as tuples second index
    unauthUsers?.forEach((user: BasicOrgFragmentForPickerFragment) => {
      const users: UserSection = emailToUserMapInner.get(user.email) ?? {
        authed: [],
        unauthed: [],
      };
      users.unauthed.push(user);
      emailToUserMapInner.set(user.email, users);
    });

    return emailToUserMapInner;
  }, [authedUsers, unauthUsers]);

  const userEmails = useMemo(() => {
    const userSections = Array.from(emailToUsersMap.entries());
    return userSections.map((section) => {
      const userEmail = section[0];
      const userSection = section[1];

      return (
        <React.Fragment key={userEmail}>
          {isInTopNav && <EmailMenuItemHeader>{userEmail}</EmailMenuItemHeader>}
          {userSection.authed.map((user) => {
            return (
              <NavOrgRow
                key={user.id}
                displayName={user.org.displayName}
                id={user.org.id}
                orgRole={user.orgRole}
                userCount={
                  user.org.userCount > 0 ? user.org.userCount : undefined
                }
              />
            );
          })}
          {userSection.unauthed.map((user) => {
            return (
              <NavOrgRow
                key={user.id}
                displayName={user.org.displayName}
                id={user.org.id}
                needsLogin={true}
              />
            );
          })}
        </React.Fragment>
      );
    });
  }, [emailToUsersMap, isInTopNav]);

  return (
    <ScrollableWorkspaceWrapper key="picker" $isInTopNav={isInTopNav}>
      {userEmails}
    </ScrollableWorkspaceWrapper>
  );
};
