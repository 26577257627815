import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";

import { HexButton } from "../../hex-components";
import { useDebouncedCallback } from "../../hooks/useDebouncedCallback";
import { ListSearchIcon } from "../icons/CustomIcons";

import { ListFilter } from "./ListFilter";

const StyledListFilter = styled(ListFilter)<{
  $show: boolean;
}>`
  width: ${({ $show }) => ($show ? "200px" : "0")};
  overflow: hidden;

  opacity: ${({ $show }) => ($show ? "1" : "0")};

  transition:
    width ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    opacity ${({ theme }) => theme.animation.durationLarge}
      ${({ theme }) => theme.animation.easing};
`;

export interface ListSearchFilterProps {
  initialFilter?: string;
  initiallyShowFilter?: boolean;
  // Note that this callback is already debounced internally within the component.
  setAlreadyDebouncedFilter: (filter: string) => void;
  setShowFilter?: (show: boolean) => void;
  loading: boolean;
  placeholder?: string;
}

export const ListSearchFilter: React.FunctionComponent<
  ListSearchFilterProps
> = ({
  initialFilter,
  initiallyShowFilter,
  loading,
  placeholder,
  setAlreadyDebouncedFilter,
  setShowFilter,
}) => {
  const [internalFilter, setInternalFilter] = useState<string>(
    initialFilter ?? "",
  );
  // If initiallyShow is true or there's already a pre-populated filter, show the filter immediately.
  // This is so we handle tab switches between tabs rendered via HomeProjectList && TrashHomeTab and not lose filter
  // state.
  const [showFilter, setShowFilterInternal] = useState<boolean>(
    initiallyShowFilter
      ? initiallyShowFilter
      : initialFilter
        ? initialFilter.length > 0
        : false,
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedFilterCallback = useDebouncedCallback(
    setAlreadyDebouncedFilter,
    250,
    { onUnmount: "cancel" },
  );

  const handleSetInternalFilter = useCallback(
    (newFilter: string) => {
      setInternalFilter(newFilter);
      debouncedFilterCallback(newFilter);
    },
    [debouncedFilterCallback],
  );

  const handleSetShowFilter = useCallback(
    (show: boolean) => {
      if (setShowFilter) {
        setShowFilter(show);
      }
      setShowFilterInternal(show);
    },
    [setShowFilter],
  );

  const handleListSearchClick = useCallback(() => {
    handleSetShowFilter(true);
    if (inputRef.current) {
      // Wrap in a timeout to give the input element a chance to render.
      // A timeout of 0 seems reliable enough for the main thread to swap and render the input element.
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [handleSetShowFilter]);

  return (
    <span
      css={`
        display: flex;
        align-content: center;
      `}
    >
      {!showFilter && (
        <HexButton
          icon={<ListSearchIcon />}
          minimal={true}
          onClick={handleListSearchClick}
        />
      )}
      <StyledListFilter
        $show={showFilter}
        filter={internalFilter}
        inputRef={inputRef}
        loading={loading}
        placeholder={placeholder}
        onSetFilter={handleSetInternalFilter}
      />
    </span>
  );
};
